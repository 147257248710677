<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Reporte - Mincetur</strong>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" @submit.prevent="Validate">
              <b-row>
                <b-col md="4"></b-col>

                <b-col md="2">
                  <b-form-group label="Seleccione un mes:">
                    <b-form-input type="month" class="text-center" v-model="report.date"></b-form-input>
                    <small v-if="errors.code" class="form-text text-danger">Ingrese una fecha</small>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label=".">
                    <b-button type="button" class="form-control btn btn-success" variant="success"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>
                <b-col md="1">
                  <b-form-group label=".">
                    <b-button type="submit" class="form-control btn btn-primary" variant="primary"><i class="fas fa-search"></i></b-button>
                  </b-form-group>
                </b-col>
      
              </b-row>
            </b-form>
 
            <b-row>
              <b-col md="2">
                <b-card>
                  <div class="w-100 text-center">
                    <span>NRO DE ARRIBOS DE HUÉSPEDES POR DÍA DEL MES</span>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center">Dia</th>
                          <th class="text-center">Arribos</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in arrivals_by_day" :key="it">
                        <tr :class="item.date == 'Total' ? 'bg-dark':''">
                          <td class="text-center">{{item.date}}</td>
                          <td class="text-right">{{item.number_of_arrivals}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </b-col>


              <b-col md="3">
                <b-card>
                  <div class="w-100 text-center">
                    <span>ARRIBOS Y PERNOCTACIONES DE EXTRANJEROS</span>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center">Pais</th>
                          <th class="text-center">Arribos</th>
                          <th class="text-center">Noches</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in arrivals_to_country" :key="it">
                        <tr :class="item.country == 'Total Extranjeros' ? 'bg-dark':''">
                          <td class="text-left">{{item.country}}</td>
                          <td class="text-right">{{item.number_of_arrivals}}</td>
                          <td class="text-right">{{item.nights}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </b-col>


              <b-col md="3">
                <b-card>
                  <div class="w-100 text-center">
                    <span>ARRIBOS Y PERNOCTACIONES DE PERUANOS</span>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center">Pais</th>
                          <th class="text-center">Arribos</th>
                          <th class="text-center">Noches</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in arrivals_to_regions" :key="it">
                        <tr :class="item.regions == 'Total Nacionales' ? 'bg-dark':''">
                          <td class="text-left">{{item.regions}}</td>
                          <td class="text-right">{{item.number_of_arrivals}}</td>
                          <td class="text-right">{{item.nights}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </b-col>


              <b-col md="4">
                <b-card>
                  <div class="w-100 text-center">
                    <span>MOTIVO PRINCIPAL DEL VIAJE DE LOS HUESPEDES</span>
                  </div>
                  <div class="table-responsive mt-3">
                    <table class="table table-hover table-bordered">
                      <thead>
                        <tr>
                          <th class="text-center">Motivo</th>
                          <th class="text-center">Peru</th>
                          <th class="text-center">Extra.</th>
                          <th class="text-center">Total</th>
                        </tr>
                      </thead>
                      <tbody v-for="(item, it) in reason_for_trip" :key="it">
                        <tr>
                          <td class="text-left">{{item.reason}}</td>
                          <td class="text-right">{{item.number_of_arrivals_pe}}</td>
                          <td class="text-right">{{item.number_of_arrivals_ex}}</td>
                          <td class="text-right bg-dark text-white">{{item.number_of_arrivals_total}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-card>
              </b-col>

            </b-row>
            


          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style>
.table-responsive{
  min-height: 15px !important;
}
</style>
<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");

export default {
  name: "UsuarioAdd",
  components:{
      vSelect,
  },
  data() {
    return {
      module: "ReportGuest",
      role: 1,
      arrivals_by_day:[],
      arrivals_to_country:[],
      arrivals_to_regions:[],
      reason_for_trip:[],
      report: {
        date: moment(new Date()).local().format("YYYY-MM"),
      },

      perPage: 20,
      currentPage: 1,
      rows: 0,
      reports: [],

      //errors
      errors: {
        from: false,
        to: false,
      },
      validate: false,
    };
  },
  mounted() {
  

  },
  methods: {
    Report,
    Validate,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function Report(_this) {
  

  let me = _this;
  let url = me.url_base + "report/mincetur";
  let data = me.report;

  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module, role: me.role, },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.arrivals_by_day = response.data.result.arrivals_by_day;
        me.arrivals_by_day.push({date: 'Total',number_of_arrivals :response.data.result.total_arrivals_by_day})

        me.arrivals_to_country = response.data.result.arrivals_to_country;
        me.arrivals_to_country.push({country: 'Total Extranjeros',number_of_arrivals :response.data.result.total_arrivals_to_country,nights :response.data.result.total_night_country})

        me.arrivals_to_regions = response.data.result.arrivals_to_regions;
        me.arrivals_to_regions.push({regions: 'Total Nacionales',number_of_arrivals :response.data.result.total_arrivals_to_regions,nights :response.data.result.total_night_regions})

        me.reason_for_trip = response.data.result.reason_for_trip;
        
        
      }
    })
  
}

function Validate() {
  this.errors.date = this.report.date.length == 0 ? true : false;
  if (this.errors.date) { this.validate = true; return false;}else{ this.validate = false; }

  Report(this);

}
</script>
